const DEFAULT_LOCALE = "en";

export const getCurrentLocale = () => {
  if (typeof document === 'undefined') {
    return DEFAULT_LOCALE;
  }
  return document.querySelector("html").getAttribute("lang") || DEFAULT_LOCALE;
}

export const isDefaultLocale = (locale) => {
  return locale === DEFAULT_LOCALE;
}

export {
  DEFAULT_LOCALE
};
