import { excludes } from './util';

const httpStatusCode = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  FAILED_DEPENDENCY: 424,
  NO_RESPONSE: 0,
};

export const isStatusCritical = (status) => {
  if (status === null || status === undefined) {
    return false;
  }

  return excludes(status, [
    httpStatusCode.BAD_REQUEST,
    httpStatusCode.NOT_FOUND,
    httpStatusCode.UNPROCESSABLE_ENTITY,
    httpStatusCode.FAILED_DEPENDENCY,
    httpStatusCode.UNAUTHORIZED,
    httpStatusCode.FORBIDDEN,
    httpStatusCode.CONFLICT,
    httpStatusCode.NO_RESPONSE,
  ]);
}

export const isStatusUnauthorized = (status) => {
  return [httpStatusCode.UNAUTHORIZED, httpStatusCode.FORBIDDEN].includes(status);
}
