import creditCardType from 'credit-card-type';
import { DEFAULT_LOCALE } from "./language-helpers";

export const trimValue = value => value.trim();

export const findCardType = cardNumber => {
  const value = cardNumber.substr(0, 4);
  // XXX: visa is set by default so we need to add validation for value length
  if (value.length < 4) {
    return null;
  }
  const match = creditCardType(value).find(
    item => ['visa', 'mastercard'].indexOf(item.type) > -1,
  );
  if (!match) {
    return null;
  }

  return match.type;
};

export const excludes = (needle, haystack) =>
  haystack.includes(needle) === false;

export const copyToClipboard = str => {
  if (typeof document === 'undefined') {
    return;
  }

  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const getNumbersFromString = str => str.replace(/[^\d.]/g, '');

export const removeWhitespaces = str => str.replace(/\s/g, '');

export const parseAmount = str => {
  return Number.parseFloat(str).toLocaleString('fullwide', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 12,
  });
};

export const matchStringByTagUl = str =>
  str.replace(/\r?\n|\r|\t/g, '').match(/<ul>.*?<\/ul>/g);

export const getLocaleByLanguage = lang => {
  const locales = {
    en: 'en-GB',
    ru: 'ru-RU',
    lv: 'lv-LV',
    es: 'es-ES',
    it: 'it-IT',
    fr: 'fr-FR',
    de: 'de-DE',
    pt: 'pt-PT',
    ko: 'ko-KR',
    ar: 'ar-AE',
    zh: 'zh-CN',
  };

  if (!locales[lang]) {
    return locales[DEFAULT_LOCALE];
  }

  return locales[lang];
};

export const getCsrf = () => {
  const { content = null } =
    document.head.querySelector('meta[name="csrf-token"]') || {};
  if (content === null) {
    throw new Error('Could not find csrf-token from tag META in HEAD section');
  }

  return content;
};
