import axiosRetry from "axios-retry";
import csrfToken from "../helpers/csrfToken";
import { captureException } from '@paybis/frontend-common-lib/src/plugins/sentry';
import axios from './network';

import TokenService from "@paybis/frontend-common-lib/src/services/token";
import { isStatusCritical } from '@paybis/frontend-common-lib/src/services/error';

const language = typeof document !== 'undefined' &&  document.querySelector("html").getAttribute("lang");

const instance = axios.create({
  headers: {
    "Accept-Language": language,
  },
});

axiosRetry(instance, { retries: 3 });

instance.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] = "application/json";

    config.timeout = config.timeout || 30000; // timeout request

    if (TokenService.hasToken()) {
      config.headers["Authorization"] = `Bearer ${TokenService.getToken()}`;
    } else if(TokenService.hasRefreshToken()){
        const jwt = await requestNewToken(config);
        config.headers["Authorization"] = `Bearer ${jwt}`;
    }

    const { method, url } = config;

    if (method !== "get" && url.indexOf("/") === 0) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
      config.headers["X-Requested-With"] = "XMLHttpRequest";
    }

    config.headers['X-User-Locale'] = language;

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config = {}, response = null } = error;
    if (response === undefined) {
      return Promise.reject('Request failed with non-defined response');
    }

    if (response === null) {
      return Promise.reject('Request failed with empty response');
    }

    const { headers = {}, status = null } = response;
    let redirectUrl = headers["x-redirect"] || null;
    if (redirectUrl !== null) {
      window.location.assign(redirectUrl);
      return new Promise(() => {});
    }

    if ([401, 403].includes(status)) {
      const jwt = await requestNewToken(config);
      if (jwt) {
        config.headers["Authorization"] = `Bearer ${jwt}`;
        return instance.request(config);
      }
    }

    if (isStatusCritical(status)) {
      captureException({ error });
    }
    return Promise.reject(response);
  }
);

async function requestNewToken(config) {
    const {url: requestUrl = ""} = config;
    if (
        requestUrl.indexOf("/jwt/refresh") === -1 &&
        requestUrl.indexOf("/") !== 0
    ) {
      const { jwt } = await TokenService.requestNewToken();
      return jwt;
    }
}

export default instance;
